const OrderRow = ({ order, indexKey }) => {
  return (
    <tr key={indexKey}>
      <td>{order.id}</td>
      <td>{order.order_id}</td>
      <td>{order.qrcode_id}</td>
      <td>{order.category_name}</td>
      <td>{order.order_item_name}</td>
      <td>{order.meta_data.email}</td>
      <td>{order.meta_data.phone}</td>
      <td>
        <ul>
          {Object.entries(order.shipping_address).map(([index, value]) => (
            <li key={index}>{`${index}: ${value}`}</li>
          ))}
        </ul>
      </td>
      <td>{order.created_at}</td>
    </tr>
  );
};

export default OrderRow;
