import { useEffect, useState } from "react";
import OrderRow from "../../components/order/OrderRow";
import axios from "axios";
import Paginate from "../../components/shared/Paginate";
import { getToken, redirectToHome, removeToken } from "../../services/authService";

const OrdersPage = () => {
  // Set Up State Hooks
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [qrcodeIdFilter, setQrcodeIdFilter] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      const token = getToken();
      if (!token) {
        removeToken();
        redirectToHome();
      }

      const res = await axios
        .get(process.env.REACT_APP_BASE_URL + "/admin/orders", {
          params: {
            search: searchQuery,
            page: currentPage,
            qrcode_id: qrcodeIdFilter,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setOrders(response.data.content.data);
          setCurrentPage(currentPage);
          setTotalPages(response.data.content.last_page);
          setLoading(false);
        })
        .catch((err) => {
          // setError(JSON.stringify(err.response.data.error_description[0]));

          alert(err.response.data.error_description[0]);
          if (
            err.response.data.error_code === 401 &&
            window.location.pathname !== "/"
          ) {
            removeToken();
            redirectToHome();
          }
        });
    };

    fetchOrders();
  }, [searchQuery, currentPage, qrcodeIdFilter]); // Re-fetch when filters change

  // Implement Filter Handling
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setQrcodeIdFilter(value);
  };

  // Render the Component
  return (
    <div className="orders-page">
      <h1 className="my-5">Orders</h1>

      <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-md-5">
        {/* Filters */}
        <input
          type="text"
          inputmode="numeric"
          pattern="[0-9\s]{13,19}"
          maxlength="19"
          name="qrcode_id"
          onChange={handleFilterChange}
          className="form-control input-filters me-4"
          placeholder="Filter by QRCode ID"
        />
        <input
          type="text"
          name="search"
          onChange={handleSearchChange}
          className="form-control input-filters"
          placeholder="Type here to search"
        />
      </div>
      <hr />
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ) : (
        <>
          {/* Body */}
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>ID</th>
                  <th style={{ width: "auto" }}>Order ID</th>
                  <th style={{ width: "auto" }}>QRCode ID</th>
                  <th style={{ width: "auto" }}>Category</th>
                  <th style={{ width: "auto" }}>Product Name</th>
                  <th style={{ width: "auto" }}>Customer E-mail</th>
                  <th style={{ width: "auto" }}>Customer Phone</th>
                  <th style={{ width: "20%" }}>Shipping Address</th>
                  <th style={{ width: "auto" }}>Order Date</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(orders).map(([index, order]) => (
                  <OrderRow order={order} key={index} />
                ))}
              </tbody>
            </table>
          </div>
          <Paginate
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </>
      )}
    </div>
  );
};
export default OrdersPage;
