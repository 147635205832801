import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Table } from "react-bootstrap";
import Paginate from "../../components/shared/Paginate";
import SwapModal from "../../components/qr/SwapModal";
import TransactionRow from "../../components/qr/TransactionRow";
import { getToken, redirectToHome, removeToken } from "../../services/authService";

const SwapPage = () => {
  const [transactions, setTransactions] = useState([]);
  const [showSwapModal, setShowSwapModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [filters, setFilters] = useState({
    qrcode_id: null,
    type: null,
    all: null,
    page: null,
    per_page: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Fetch Swap Transactions on Component Mount
  useEffect(() => {
    const fetchSwapTransactions = async () => {
      const token = getToken();
      if (!token) {
        removeToken();
        redirectToHome();
      }
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + "/admin/qrcodes/swap/history",
          {
            params: {
              ...filters,
              page: currentPage,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.result === "success") {
          setTransactions(response.data.content.data);
          setTotalPages(response.data.content.last_page);
          setLoading(false);
        } else {
          throw new Error("Failed to fetch transactions");
        }
      } catch (error) {
        // setError(error.message || "An error occurred");
        // setLoading(false);
        alert(error.response.data.error_description[0]);

        if (
          error.response.data.error_code === 401 &&
          window.location.pathname !== "/"
        ) {
          removeToken();
          redirectToHome();
        }
      }
    };

    fetchSwapTransactions();
  }, [filters, currentPage]); // Re-fetch when filters change

  // Triggers re-fetching of transactions
  const refreshTransactions = () => {
    setFilters({ ...filters });
  };

  // Implement Filter Handling
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Rollback swap
  const handleRollback = async (swapId) => {
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/qrcodes/swap/rollback`,
        {
          swap_id: swapId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Refresh transactions list
      setFilters({ ...filters }); // Triggers re-fetching
    } catch (error) {
      console.error("Failed to rollback swap", error);
    }
  };

  // Render the Component
  return (
    <div className="swap-page">
      <h1 className="my-5">Swap transaction history</h1>
      {/* ======== SWAP QR BUTTON ========= */}
      <Button variant="secondary" onClick={() => setShowSwapModal(true)}>
        SWAP QR
      </Button>
      <SwapModal
        show={showSwapModal}
        onHide={() => setShowSwapModal(false)}
        refreshTransactions={refreshTransactions}
      />

      <hr />
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ) : (
        <>
          {/* Filters */}
          <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-content-start mb-md-5">
            {/* Filter by Type */}
            <select
              name="type"
              onChange={handleFilterChange}
              value={filters.type}
              className="form-select input-filters me-4"
            >
              <option value="">Select Print Status</option>
              <option value="Swap">Swap</option>
              <option value="rollback">Rollback</option>
            </select>

            {/* filter by QRcode ID */}
            <input
              type="text"
              name="qrcode_id"
              onChange={handleFilterChange}
              className="form-control input-filters"
              placeholder="Filter By QRcode ID"
            />
          </div>

          {/* Body */}
          <div className="table-responsive">
            <Table className="table" striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Old QRCode ID</th>
                  <th>New QRCode ID</th>
                  <th>Old User Name</th>
                  <th>New User Name</th>
                  <th>Type</th>
                  <th>Timestamp</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(transactions).map(([index, transaction]) => (
                  <TransactionRow
                    key={index}
                    transaction={transaction}
                    handleRollback={handleRollback}
                  />
                ))}
              </tbody>
            </Table>
          </div>
          <Paginate
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </>
      )}
    </div>
  );
};

export default SwapPage;
