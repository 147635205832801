import { Modal, Button } from "react-bootstrap";

const NewQRModal = ({
  showNewQRForm,
  setShowNewQRForm,
  handleNewQRSubmit,
  newQRCategory,
  setNewQRCategory,
  categories,
  newQRCode,
  setNewQRCode,
  modalError,
  setModalError,
  isLoadingSpin,
}) => {
  return (
    <Modal show={showNewQRForm} onHide={() => setShowNewQRForm(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add New QR Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleNewQRSubmit}>
          <div className="mb-3">
            <label htmlFor="newQRCategory" className="form-label">
              Category
            </label>
            <select
              name="category_id"
              id="newQRCategory"
              value={newQRCategory}
              onChange={(e) => setNewQRCategory(e.target.value)}
              className="form-control form-select"
              required
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.title}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="newQRCode" className="form-label">
              Code
            </label>
            <input
              type="text"
              name="code"
              id="newQRCode"
              value={newQRCode}
              onChange={(e) => setNewQRCode(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="container mt-1 p-0">
            {modalError && (
              <div className="alert alert-danger" role="alert">
                {modalError}
              </div>
            )}
          </div>
          <Button variant="success" type="submit" disabled={isLoadingSpin}>
            Save QR
            {isLoadingSpin && <span className="spinner-inline"></span>}
          </Button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShowNewQRForm(false);
            setModalError("");
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewQRModal;
