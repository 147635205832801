import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Paginate from "../../components/shared/Paginate";
import FaqRow from "../../components/faq/FaqRow";
import AddModal from "../../components/faq/AddModal";
import EditFaqModal from "../../components/faq/EditFaqModal";
import { getToken, redirectToHome, removeToken } from "../../services/authService";

const FaqPage = () => {
  // Set Up State Hooks
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [error, setError] = useState("");
  const [modalError, setModalError] = useState("");
  const [editFormError, setEditFormError] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState(null);
  const [isLoadingSpin, setIsLoadingSpin] = useState(false);

  // NEW Form state
  const [showNewForm, setShowNewForm] = useState(false);

  const handleCloseAddForm = () => {
    setShowNewForm(false);
    setModalError("");
  };

  const [newFaq, setNewFaq] = useState({
    question: "",
    answer: "",
    published: false,
  });
  // Edit & Delete options states
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditForm = () => {
    setShowEditModal(false);
    setModalError("");
  };

  const [currentOpenedItem, setCurrentOpenedItem] = useState({});
  const [editFormData, setEditFormData] = useState({
    id: null,
    question: "",
    answer: "",
    published: false,
  });

  const [updateTrigger, setUpdateTrigger] = useState(0); // Add this line

  // Fetch FAQ on Component Mount
  useEffect(() => {
    const fetchFaqs = async () => {
      const token = getToken();
      if (!token) {
        removeToken();
        redirectToHome();
      }
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + "/admin/faq",
          {
            params: {
              search: searchQuery,
              page: currentPage,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.result === "success") {
          setFaqs(response.data.content.data);
          setCurrentPage(currentPage);
          setTotalPages(response.data.content.last_page);
          setLoading(false);
        } else {
          throw new Error("Failed to fetch FAQs");
        }
      } catch (error) {
        // setError(error.message || "An error occurred");
        // setLoading(false);
        alert(error.response.data.error_description[0]);

        if (
          error.response.data.error_code === 401 &&
          window.location.pathname !== "/"
        ) {
          removeToken();
          redirectToHome();
        }
      }
    };

    fetchFaqs();
  }, [searchQuery, currentPage, updateTrigger]); // Re-fetch when filters change

  // Implement Filter Handling
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  // Handle Add faq Form Submit
  const handleAddSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingSpin(true); // Start loading
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/faq`,
        newFaq,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle successful submission
      if (response.data.result === "success") {
        setUpdateTrigger((prev) => prev + 1);
        setTotalPages(response.data.content.last_page);
        setCurrentPage(currentPage);
        setLoading(false);
      }
    } catch (modalError) {
      // Handle errors, e.g., show an error message
      setModalError(
        modalError.response.data.error_description[0] || "An error occurred"
      );
    } finally {
      setIsLoadingSpin(false);
      handleCloseAddForm();
    }
  };

  // Implement Filter Handling
  const handleNewFormChange = (e) => {
    let { name, value } = e.target;
    if (name === "published") {
      if (e.target.checked) {
        value = 1;
      } else {
        value = 0;
      }
    }
    setNewFaq((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle Edit QR Form Changes
  const handleEditFormChange = (e) => {
    let { name, value } = e.target;
    if (name === "published") {
      if (e.target.checked) {
        value = 1;
      } else {
        value = 0;
      }
    }
    setEditFormData((prev) => ({ ...prev, [name]: value }));
    console.log("after " + name + ":" + value);
  };

  const handleShowEditModal = (faq) => {
    setCurrentOpenedItem(faq);
    setShowEditModal(true);

    setEditFormData({
      id: faq.id,
      question: faq.question,
      answer: faq.answer,
      published: faq.published,
    });
  };

  // Handle Edit Form Submit
  const handleEditFormSubmit = async (e) => {
    e.preventDefault();

    // Basic validation example
    if (!editFormData.question || !editFormData.answer) {
      setEditFormError("Please fill in all required fields correctly.");
      return;
    }
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/admin/faq/${editFormData.id}`,
        {
          question: editFormData.question,
          answer: editFormData.answer,
          published: editFormData.published,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle successful edit
      if (response.data.result === "success") {
        setUpdateTrigger((prev) => prev + 1);

        setTotalPages(response.data.content.last_page);
        setCurrentPage(currentPage);
        setLoading(false);
      }
    } catch (error) {
      setEditFormError("Failed to update. Please try again.");
    } finally {
      handleCloseEditForm();
    }
  };

  // Handle Delete QR
  const handleDelete = async (id) => {
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/admin/faq/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.result === "success") {
          console.log(currentPage);
          setUpdateTrigger((prev) => prev + 1);

          setTotalPages(response.data.content.last_page);
          setCurrentPage(currentPage);
          setLoading(false);
        }
      } catch (error) {
        console.error("Delete failed", error);
        alert("Failed to delete question");
      }
    }
  };

  // Render the Component
  return (
    <div className="faq-page">
      <h1 className="my-5">FAQs</h1>

      {/* ========New FAQ BUTTON ========= */}
      <div className="d-flex flex-column flex-md-row justify-content-between mb-md-5">
        <Button
          className="mb-3 mb-md-0"
          variant="secondary"
          onClick={() => setShowNewForm(true)}
        >
          New Question
        </Button>
        <AddModal
          showNewForm={showNewForm}
          handleCloseAddForm={handleCloseAddForm}
          handleAddSubmit={handleAddSubmit}
          handleNewFormChange={handleNewFormChange}
          modalError={modalError}
          setModalError={setModalError}
          isLoadingSpin={isLoadingSpin}
        />

        {/* Filters */}
        <input
          type="text"
          name="search"
          onChange={handleSearchChange}
          className="form-control input-filters"
          placeholder="Type here to search"
        />
      </div>
      <hr />
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ) : (
        <>
          {/* Body */}
          <table className="table table-responsive">
            <thead>
              <tr>
                <th style={{ width: "5%" }}>ID</th>
                <th style={{ width: "25%" }}>Question</th>
                <th style={{ width: "50%" }}>Answer</th>
                <th style={{ width: "10%" }}>Published</th>
                <th style={{ width: "10%" }}>Options</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(faqs).map(([index, faq]) => (
                <FaqRow
                  key={index}
                  faq={faq}
                  onEdit={handleShowEditModal}
                  onDelete={handleDelete}
                />
              ))}
              {/* Edit Modal */}
              <EditFaqModal
                showEditModal={showEditModal}
                handleCloseEditForm={handleCloseEditForm}
                currentOpenedItem={currentOpenedItem}
                handleEditFormSubmit={handleEditFormSubmit}
                editFormData={editFormData}
                handleEditFormChange={handleEditFormChange}
                editFormError={editFormError}
                setEditFormError={setEditFormError}
                isLoadingSpin={isLoadingSpin}
              />
            </tbody>
          </table>
          <Paginate
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </>
      )}
    </div>
  );
};

export default FaqPage;
