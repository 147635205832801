import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import QRRow from "../../components/qr/QRRow";
import Paginate from "../../components/shared/Paginate";
import EditQRModal from "../../components/qr/EditQRModal";
import BulkGenerateModal from "../../components/qr/BulkGenerateModal";
import NewQRModal from "../../components/qr/NewQRModal";
import {
  getToken,
  redirectToHome,
  removeToken,
} from "../../services/authService";

const QrcodesPage = () => {
  // Set Up State Hooks
  const [qrCodes, setQrCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [error, setError] = useState("");
  const [modalError, setModalError] = useState("");
  const [filters, setFilters] = useState({
    category_id: null,
    assigned: null,
    printed: null,
    all: null,
    page: null,
    page_size: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedQRCodes, setSelectedQRCodes] = useState([]);
  const categories = JSON.parse(localStorage.getItem("categories"));
  const passcode_types = JSON.parse(localStorage.getItem("passcode_types"));
  const [isLoadingSpin, setIsLoadingSpin] = useState(false);

  // NEW QR Form state
  const [showNewQRForm, setShowNewQRForm] = useState(false);
  const [newQRCategory, setNewQRCategory] = useState("");
  const [newQRCode, setNewQRCode] = useState("");

  // Bulk Generate Form state
  const [showBulkGenerateModal, setShowBulkGenerateModal] = useState(false);
  const [bulkCategory, setBulkCategory] = useState("");
  const [bulkCount, setBulkCount] = useState("");

  // Edit & Delete options states
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentOpenedQRCode, setCurrentOpenedQRCode] = useState({});
  const [editFormData, setEditFormData] = useState({
    id: null,
    category_id: "",
    passcode_type: "",
    passcode: null,
    code: "",
  });
  const [editFormError, setEditFormError] = useState("");

  // Fetch QR Codes on Component Mount
  useEffect(() => {
    const fetchQRCodes = async () => {
      const token = getToken();
      if (!token) {
        removeToken();
        redirectToHome();
      }
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + "/admin/qrcodes",
          {
            params: {
              ...filters,
              page: currentPage,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.result === "success") {
          setQrCodes(response.data.content.data);
          setTotalPages(response.data.content.last_page);
          setLoading(false);
        } else {
          throw new Error("Failed to fetch QR codes");
        }
      } catch (error) {
        // setError(error.message || "An error occurred");
        // setLoading(false);
        alert(error.response.data.error_description[0]);

        if (
          error.response.data.error_code === 401 &&
          window.location.pathname !== "/"
        ) {
          removeToken();
          redirectToHome();
        }
      }
    };

    fetchQRCodes();
  }, [filters, currentPage]); // Re-fetch when filters change

  // Implement Checkbox Selection
  const handleSelectQRCode = (id) => {
    setSelectedQRCodes((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((qrId) => qrId !== id)
        : [...prevSelected, id]
    );
  };

  // Implement Filter Handling
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Export to PDF Button
  const exportToPDF = async () => {
    setIsLoadingSpin(true); // Start loading
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/admin/qrcodes/export",
        {
          params: {
            code_ids: selectedQRCodes,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      // Check for a PDF response based on the 'Content-Type' header
      const contentType = response.headers["content-type"];

      if (contentType === "application/pdf") {
        // Create a URL for the blob
        const fileToDownload = new Blob([response.data], {
          type: "application/pdf",
        });
        const url = window.URL.createObjectURL(fileToDownload);
        const link = document.createElement("a");
        link.href = url;

        const contentDisposition = response.headers["content-disposition"];
        let filename = "qrcodes.pdf";

        if (contentDisposition) {
          const match = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (match && match[1]) {
            // Remove quotes if present
            filename = match[1].replace(/['"]/g, "");
          }
        }
        console.log("filename " + filename);
        console.log("contentDisposition " + contentDisposition);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

        // Reset selectedQRCodes after successful download
        setSelectedQRCodes([]);

        // Clean up
        window.URL.revokeObjectURL(url);
        link.parentNode.removeChild(link);
      } else {
        alert(error.response.data.error_description[0] || "Export failed");
        // Assuming an error, try to read as text to get the JSON message
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const errorResponse = JSON.parse(reader.result);
            console.error("Error downloading PDF:", errorResponse);
            alert(
              errorResponse.error_description.join("\n") || "An error occurred."
            );
          } catch (e) {
            console.error("Failed to parse error response:", e);
            alert("An unknown error occurred.");
          }
        };
        reader.readAsText(response.data);
      }
    } catch (error) {
      // Axios throws an error for non-2xx responses. Handle it here.
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx and possibly contains a blob
        const errorData = error.response.data;

        // Since we expect a JSON for errors, convert the blob to text
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const errorResponse = JSON.parse(reader.result);
            alert(
              `${
                errorResponse.error_description.join("\n") ||
                "An error occurred."
              }`
            );
          } catch (e) {
            // Not JSON or not readable as such
            alert("An unknown error occurred.");
          }
        };
        reader.onerror = () => {
          alert("An error occurred while reading the error response.");
        };
        reader.readAsText(errorData);
      } else {
        // The request was made but no response was received or an error occurred in setting up the request
        console.log(error.message);
        alert("An error occurred, and no server response was received.");
      }
    } finally {
      setIsLoadingSpin(false);
    }
  };

  // Handle NewQR Submit
  const handleNewQRSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setIsLoadingSpin(true); // Start loading
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/qrcodes`,
        {
          category_id: newQRCategory,
          code: newQRCode,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle successful submission
      setShowNewQRForm(false);
      setNewQRCategory("");
      setNewQRCode("");
      setLoading(false);
      // Optionally, refresh the list of QR codes or add the new QR code to the state directly
    } catch (modalError) {
      // Handle errors, e.g., show an error message
      setModalError(
        modalError.response.data.error_description[0] || "An error occurred"
      );
    } finally {
      setIsLoadingSpin(false);
    }
  };

  // Handle Bulk Generate QR
  const handleBulkGenerateSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingSpin(true); // Start loading

    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/qrcodes/bulk/generate`,
        {
          params: {
            category_id: bulkCategory,
            count: bulkCount,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.result === "success") {
        setSelectedQRCodes([]);
        setFilters((prevFilters) => ({
          ...prevFilters,
          page: 1, // Set `page` to 1, keeping all other fields unchanged
        }));
        setQrCodes(response.data.content.data);
        setTotalPages(response.data.content.last_page);

        // Handle successful response
        setShowBulkGenerateModal(false); // Close the modal
        setBulkCategory(""); // Reset the form fields
        setBulkCount("");
        // Optionally refresh the QR codes list here
        setLoading(false);
      }
    } catch (modalError) {
      // Handle errors
      setModalError(
        modalError.response.data.error_description[0] || "An error occurred"
      );
    } finally {
      setIsLoadingSpin(false); // End loading
    }
  };

  // Handle Bulk Delete
  const handleBulkDelete = async () => {
    if (selectedQRCodes.length < 1) {
      alert("No QR code selected!");
    } else {
      if (
        window.confirm("Are you sure you want to delete the selected QR codes?")
      ) {
        setIsLoadingSpin(true); // Start loading
        const token = getToken();
        if (!token) {
          removeToken();
          redirectToHome();
        }
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/admin/qrcodes/bulk/delete`,
            {
              params: { ids: selectedQRCodes },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // Refresh data or handle success
          if (response.data.result === "success") {
            setSelectedQRCodes([]);
            setFilters((prevFilters) => ({
              ...prevFilters,
              page: 1, // Set `page` to 1, keeping all other fields unchanged
            }));
            setQrCodes(response.data.content.data);
            setTotalPages(response.data.content.last_page);
            setLoading(false);
          }
        } catch (error) {
          // Handle errors
          alert(
            error.response.data.error_description[0] || "An error occurred"
          );
        } finally {
          setIsLoadingSpin(false);
        }
      }
    }
  };

  // Handle Edit QR Form Changes
  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleShowEditModal = (qrCode) => {
    setCurrentOpenedQRCode(qrCode); // Store the selected QR code's data
    setShowEditModal(true);

    setEditFormData({
      id: qrCode.id,
      category_id: qrCode.category_id,
      passcode_type: qrCode.passcode_type,
      passcode: qrCode.passcode || null,
    });
  };

  // Handle Edit Form Submit
  const handleEditFormSubmit = async (e) => {
    e.preventDefault();

    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    // Basic validation example
    if (
      !editFormData.id ||
      !editFormData.category_id ||
      !editFormData.passcode_type ||
      (editFormData.passcode_type === "Passcode" &&
        !editFormData.passcode.match(/^[0-9]{1,4}$/))
    ) {
      setEditFormError("Please fill in all required fields correctly.");
      return;
    }

    // Start with required data
    let requestData = {
      category_id: editFormData.category_id,
      passcode_type: editFormData.passcode_type,
    };

    // Conditionally add passcode if it's not null
    if (editFormData.passcode_type === "Passcode") {
      requestData.passcode = editFormData.passcode;
    }
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/admin/qrcodes/${editFormData.id}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle successful edit
      setShowEditModal(false); // Close the modal
      // Optionally refresh your list of QR codes here
      if (response.data.result === "success") {
        setFilters((prevFilters) => ({
          ...prevFilters,
          page: currentPage, // Set `page` to 1, keeping all other fields unchanged
        }));
        setQrCodes(response.data.content.data);
        setTotalPages(response.data.content.last_page);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating QR code:", error);
      setEditFormError("Failed to update QR code. Please try again.");
    }
  };

  // Handle Delete QR
  const handleDeleteQR = async (id) => {

    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    if (window.confirm("Are you sure you want to delete this QR code?")) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/admin/qrcodes/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.result === "success") {
          setFilters((prevFilters) => ({
            ...prevFilters,
            page: currentPage, // Set `page` to 1, keeping all other fields unchanged
          }));
          setQrCodes(response.data.content.data);
          setTotalPages(response.data.content.last_page);
          setLoading(false);
        }
      } catch (error) {
        console.error("Delete failed", error);
        alert("Failed to delete QR code");
      }
    }
  };

  // Render the Component
  return (
    <div className="qrcodes-page">
      <h1 className="my-5">QR Codes</h1>

      {/* ========New QR BUTTON ========= */}
      <Button
        className="w-md-auto"
        variant="secondary"
        onClick={() => setShowNewQRForm(true)}
      >
        New QR
      </Button>
      <NewQRModal
        showNewQRForm={showNewQRForm}
        setShowNewQRForm={setShowNewQRForm}
        handleNewQRSubmit={handleNewQRSubmit}
        newQRCategory={newQRCategory}
        setNewQRCategory={setNewQRCategory}
        categories={categories}
        newQRCode={newQRCode}
        setNewQRCode={setNewQRCode}
        modalError={modalError}
        setModalError={setModalError}
        isLoadingSpin={isLoadingSpin}
      />

      {/* ==== Generate Bulk QR ==== */}
      <Button
        className="ms-3 w-md-auto"
        variant="secondary"
        onClick={() => setShowBulkGenerateModal(true)}
      >
        Generate Bulk QR
      </Button>
      <BulkGenerateModal
        showBulkGenerateModal={showBulkGenerateModal}
        setShowBulkGenerateModal={setShowBulkGenerateModal}
        handleBulkGenerateSubmit={handleBulkGenerateSubmit}
        bulkCategory={bulkCategory}
        setBulkCategory={setBulkCategory}
        categories={categories}
        bulkCount={bulkCount}
        setBulkCount={setBulkCount}
        modalError={modalError}
        isLoadingSpin={isLoadingSpin}
        setModalError={setModalError}
      />
      <hr />
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ) : (
        <>
          {/* Filters */}
          <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
            <select
              name="category_id"
              onChange={handleFilterChange}
              value={filters.category_id}
              className="form-select input-filters"
            >
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.title}
                </option>
              ))}
            </select>

            {/* New Printed Switch */}
            <select
              name="printed"
              onChange={handleFilterChange}
              value={filters.printed}
              className="form-select input-filters"
            >
              <option value="">Select Print Status</option>
              <option value="1">True</option>
              <option value="0">False</option>
            </select>
            {/* New Assigned Switch */}
            <select
              name="assigned"
              onChange={handleFilterChange}
              value={filters.assigned}
              className="form-select input-filters"
            >
              <option value="">Select Assign Status</option>
              <option value="1">True</option>
              <option value="0">False</option>
            </select>

            {/* Bulk Delete */}
            <button
              className="btn btn-danger me-md-2 input-filters"
              onClick={handleBulkDelete}
              disabled={isLoadingSpin}
            >
              Bulk Delete
              {isLoadingSpin && <span className="spinner-inline"></span>}
            </button>

            <button
              className="btn btn-secondary input-filters"
              style={{ cursor: "pointer", float: "right" }}
              onClick={exportToPDF}
              disabled={isLoadingSpin}
            >
              Export to PDF
              {isLoadingSpin && <span className="spinner-inline"></span>}
            </button>
          </div>
          {/* Body */}
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Select</th>
                  <th>ID</th>
                  <th>Code</th>
                  <th>Passcode Type</th>
                  <th>Category Name</th>
                  <th>Assigned</th>
                  <th>Printed</th>
                  <th>Scanned</th>
                  <th>QR Image</th>
                  <th>Barcode Image</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {qrCodes.map((qrCode) => (
                  <QRRow
                    key={qrCode.id}
                    qrCode={qrCode}
                    onEdit={handleShowEditModal}
                    onDelete={handleDeleteQR}
                    onChange={handleSelectQRCode}
                    selectedQRCodes={selectedQRCodes}
                  />
                ))}
                {/* Edit Modal */}
                <EditQRModal
                  showEditModal={showEditModal}
                  setShowEditModal={setShowEditModal}
                  currentOpenedQRCode={currentOpenedQRCode}
                  handleEditFormSubmit={handleEditFormSubmit}
                  editFormData={editFormData}
                  handleEditFormChange={handleEditFormChange}
                  categories={categories}
                  passcode_types={passcode_types}
                  editFormError={editFormError}
                />
              </tbody>
            </table>
          </div>
          <Paginate
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </>
      )}
    </div>
  );
};

export default QrcodesPage;
