import { Button, Badge } from "react-bootstrap";

const TransactionRow = ({ key, transaction, handleRollback }) => {
  return (
    <tr key={key}>
      <td>{transaction.id}</td>
      <td>{transaction.old_qrcode_id}</td>
      <td>{transaction.new_qrcode_id}</td>
      <td>{transaction.old_user_name}</td>
      <td>{transaction.new_user_name}</td>
      <td>
        {transaction.type === "swap" ? (
          <Badge bg="info">
            {transaction.type.charAt(0).toUpperCase() +
              transaction.type.slice(1)}
          </Badge>
        ) : (
          <Badge bg="warning">
            {transaction.type.charAt(0).toUpperCase() +
              transaction.type.slice(1)}
          </Badge>
        )}
      </td>{" "}
      <td>{transaction.swapped_at}</td>
      <td>
        {transaction.type === "swap" && (
          <Button
            variant="warning"
            onClick={() => handleRollback(transaction.id)}
          >
            Rollback
          </Button>
        )}
      </td>
    </tr>
  );
};

export default TransactionRow;
