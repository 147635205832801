import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Paginate from "../../components/shared/Paginate";
import SettingRow from "../../components/setting/SettingRow";
import AddSettingModal from "../../components/setting/AddSettingModal";
import EditSettingModal from "../../components/setting/EditSettingModal";
import { getToken, redirectToHome, removeToken } from "../../services/authService";

const SettingPage = () => {
  // Set Up State Hooks
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [error, setError] = useState("");
  const [modalError, setModalError] = useState("");
  const [editFormError, setEditFormError] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoadingSpin, setIsLoadingSpin] = useState(false);

  // NEW Form state
  const [showNewForm, setShowNewForm] = useState(false);

  const handleCloseAddForm = () => {
    setShowNewForm(false);
    setModalError(""); // Resetting modal error when modal is closed
  };

  const [newSetting, setNewSetting] = useState({
    title: "",
    key: "",
    value: "",
    show_in_config: false,
  });
  // Edit & Delete options states
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditForm = () => {
    setShowEditModal(false);
    setModalError(""); // Resetting modal error when modal is closed
  };

  const [currentOpenedItem, setCurrentOpenedItem] = useState({});
  const [editFormData, setEditFormData] = useState({
    id: null,
    title: "",
    value: "",
    show_in_config: false,
  });

  const [updateTrigger, setUpdateTrigger] = useState(0); // Add this line

  // Fetch Settings on Component Mount
  useEffect(() => {
    const fetchSettings = async () => {
      const token = getToken();
      if (!token) {
        removeToken();
        redirectToHome();
      }
      try {
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + "/admin/settings",
          {
            params: {
              search: searchQuery,
              page: currentPage,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.result === "success") {
          setSettings(response.data.content.data);
          setCurrentPage(currentPage);
          setTotalPages(response.data.content.last_page);
          setLoading(false);
        } else {
          throw new Error("Failed to fetch settings");
        }
      } catch (error) {
        // setError(error.message || "An error occurred");
        // setLoading(false);
        alert(error.response.data.error_description[0]);

        if (
          error.response.data.error_code === 401 &&
          window.location.pathname !== "/"
        ) {
          removeToken();
          redirectToHome();
        }
      }
    };

    fetchSettings();
  }, [searchQuery, currentPage, updateTrigger]); // Re-fetch when filters change

  // Implement Filter Handling
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  // Handle Add Setting Form Submit
  const handleAddSettingSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setIsLoadingSpin(true); // Start loading
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/settings`,
        newSetting,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle successful submission
      if (response.data.result === "success") {
        setUpdateTrigger((prev) => prev + 1);
        setTotalPages(response.data.content.last_page);
        setCurrentPage(currentPage);
        setLoading(false);
      }
    } catch (modalError) {
      // Handle errors, e.g., show an error message
      setModalError(
        modalError.response.data.error_description[0] || "An error occurred"
      );
    } finally {
      setIsLoadingSpin(false);
      handleCloseAddForm();
    }
  };

  // Implement Filter Handling
  const handleNewFormChange = (e) => {
    let { name, value } = e.target;
    if (name === "show_in_config") {
      if (e.target.checked) {
        value = 1;
      } else {
        value = 0;
      }
    }
    setNewSetting((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle Edit QR Form Changes
  const handleEditFormChange = (e) => {
    let { name, value } = e.target;
    if (name === "show_in_config") {
      if (e.target.checked) {
        value = 1;
      } else {
        value = 0;
      }
    }
    setEditFormData((prev) => ({ ...prev, [name]: value }));
    console.log("after " + name + ":" + value);
  };

  const handleShowEditModal = (setting) => {
    setCurrentOpenedItem(setting);
    setShowEditModal(true);

    setEditFormData({
      id: setting.id,
      title: setting.title,
      value: setting.value,
      show_in_config: setting.show_in_config,
    });
  };

  // Handle Edit Form Submit
  const handleEditFormSubmit = async (e) => {
    e.preventDefault();
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    // Basic validation example
    if (!editFormData.value || !editFormData.title) {
      setEditFormError("Please fill in all required fields correctly.");
      return;
    }
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/admin/settings/${editFormData.id}`,
        {
          title: editFormData.title,
          value: editFormData.value,
          show_in_config: editFormData.show_in_config,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle successful edit
      if (response.data.result === "success") {
        setUpdateTrigger((prev) => prev + 1);

        setTotalPages(response.data.content.last_page);
        setCurrentPage(currentPage);
        setLoading(false);
      }
    } catch (error) {
      setEditFormError("Failed to update. Please try again.");
    } finally {
      handleCloseEditForm();
    }
  };

  // Handle Delete QR
  const handleDelete = async (id) => {
    const token = getToken();
      if (!token) {
        removeToken();
        redirectToHome();
      }
    if (window.confirm("Are you sure you want to delete?")) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/admin/settings/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.result === "success") {
          console.log(currentPage);
          setUpdateTrigger((prev) => prev + 1);

          setTotalPages(response.data.content.last_page);
          setCurrentPage(currentPage);
          setLoading(false);
        }
      } catch (error) {
        console.error("Delete failed", error);
        alert("Failed to delete setting");
      }
    }
  };

  // Render the Component
  return (
    <div className="settings-page">
      <h1 className="my-5">Settings</h1>

      {/* ========New Setting BUTTON ========= */}
      <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-content-between mb-md-5">
        <Button
          className="mb-3 mb-md-0"
          variant="secondary"
          onClick={() => setShowNewForm(true)}
        >
          New Setting
        </Button>
        <AddSettingModal
          showNewForm={showNewForm}
          handleCloseAddForm={handleCloseAddForm}
          handleAddSettingSubmit={handleAddSettingSubmit}
          handleNewFormChange={handleNewFormChange}
          modalError={modalError}
          setModalError={setModalError}
          isLoadingSpin={isLoadingSpin}
        />

        {/* Filters */}
        <input
          type="text"
          name="search"
          onChange={handleSearchChange}
          className="form-control input-filters"
          placeholder="Type here to search"
        />
      </div>
      <hr />
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      ) : (
        <>
          {/* Body */}
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>ID</th>
                  <th style={{ width: "25%" }}>Title</th>
                  <th style={{ width: "25%" }}>Key</th>
                  <th style={{ width: "auto" }}>Value</th>
                  <th style={{ width: "10%" }}>Show in config</th>
                  <th style={{ width: "10%" }}>Options</th>
                </tr>
              </thead>
              <tbody>
                {settings.map((setting) => (
                  <SettingRow
                    key={setting.id}
                    setting={setting}
                    onEdit={handleShowEditModal}
                    onDelete={handleDelete}
                  />
                ))}
                {/* Edit Modal */}
                <EditSettingModal
                  showEditModal={showEditModal}
                  handleCloseEditForm={handleCloseEditForm}
                  currentOpenedItem={currentOpenedItem}
                  handleEditFormSubmit={handleEditFormSubmit}
                  editFormData={editFormData}
                  handleEditFormChange={handleEditFormChange}
                  editFormError={editFormError}
                  setEditFormError={setEditFormError}
                  isLoadingSpin={isLoadingSpin}
                />
              </tbody>
            </table>
          </div>
          <Paginate
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </>
      )}
    </div>
  );
};

export default SettingPage;
