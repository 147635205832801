import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPhotoFilm,
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";

const SimpleQRRow = ({ qrCode, assign }) => {
  return (
    <tr key={qrCode.id}>
      <td>{qrCode.id}</td>
      <td>{qrCode.code}</td>
      <td>
        {/* ====== Assign btn */}
        <Button
          variant={qrCode.has_memories ? "secondary" : "success"}
          className="btn-sm me-2"
          onClick={() => assign(qrCode)}
          disabled={qrCode.has_memories}
        >
          <FontAwesomeIcon icon={faPhotoFilm} style={{ paddingRight: "10px" }} />{" "}
          {qrCode.has_memories ? "Assigned" : "Assign Now"}
        </Button>
      </td>
      <td>{qrCode.has_memories ? <FontAwesomeIcon icon={faCheck} /> : ""}</td>
    </tr>
  );
};

export default SimpleQRRow;
