import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { getToken, redirectToHome, removeToken } from "../../services/authService";

const SwapModal = ({ show, onHide, refreshTransactions }) => {
  const [oldQrcodeId, setOldQrcodeId] = useState("");
  const [newQrcodeId, setNewQrcodeId] = useState("");
  const [modalError, setModalError] = useState("");
  const [isLoadingSpin, setIsLoadingSpin] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = getToken();
    if (!token) {
      removeToken();
      redirectToHome();
    }
    try {
      setIsLoadingSpin(true);

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/admin/qrcodes/swap`,
        {
          old_qrcode_id: oldQrcodeId,
          new_qrcode_id: newQrcodeId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onHide(); // Close the modal
      refreshTransactions(); // Refresh the list of transactions
    } catch (modalError) {
      // Handle errors, e.g., show an error message
      setModalError(
        modalError.response.data.error_description[0] || "An error occurred"
      );
    } finally {
      setIsLoadingSpin(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Swap QR Codes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Old QR Code ID</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter old QR Code ID"
              value={oldQrcodeId}
              onChange={(e) => setOldQrcodeId(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>New QR Code ID</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter new QR Code ID"
              value={newQrcodeId}
              onChange={(e) => setNewQrcodeId(e.target.value)}
              required
            />
          </Form.Group>

          <div className="container mt-1 p-0">
            {modalError && (
              <div className="alert alert-danger" role="alert">
                {modalError}
              </div>
            )}
          </div>

          <Button
            variant="primary"
            type="submit"
            className=" d-flex float-right"
            disabled={isLoadingSpin}
          >
            Swap
            {isLoadingSpin && <span className="spinner-inline"></span>}
          </Button>
          <hr />
          <p class="text-muted">
            <p>
              <strong>Note for Admin:</strong>
            </p>{" "}
            Please be aware that selecting the "Swap" action will exchange all
            details associated with the chosen QR codes, including{" "}
            <strong>Memories, Security Credentials</strong>, and{" "}
            <strong>User Ownership.</strong>
          </p>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            onHide();
            setModalError("");
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SwapModal;
